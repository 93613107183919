@import url("./fontastic-font.css");
@import "../theme/base-css.scss";

:root {
  --blueTextColor: #5ca2f7;
  --blueButtonColor: #2a86f3;
  --blueButtonHoverColor: #2a68f3;
  --blueTwo: #171b2f;
  --blueThree: #4f546e;
  --blueFour: #545c88;
  --blueFive: #2f3352;
  --blueSix: #646980;
  --blueSeven: #3281ff;
  --blueEight: #010937;
  --blueNine: #34416c;
  --blueTen: #2f386e;
  --blueEleven: #0e202e;
  --blueTwelve: #b7becb;
  --blueThirteen: #114d97;
  --blueFourteen: #3e4259;
  --blueFifteen: #1b2244;
  --blueSixteen: #1c2344;
  --blueSeventeen: #6255ff;
  --blueEighteen: #434f75;
  --blueNineteen: #152d4b;
  --enabledBtnBg: #1566f1;
  --textSecondary: #d1d2da;
  --greenText: #5ad86e;
  --bodyBg: #1e2442;
  --disabledBtnBg: #393f5c;
  --white: #ffffff;
  --textWhiteColor: #fdfdfd;
  --whiteBg: #efefef;
  --whiteTwo: #fffbfe;
  --whiteThree: #f1f1f1;
  --whiteFour: #f8f9f9;
  --black: #000000;
  --blackThree: #111111;
  --buttonSecondaryBgActive: #161d40;
  --primaryDarkBg: #080d29;
  --blackTwo: #1c1b1b;
  --secondaryDarkBg: #111739;
  --couponModalBgColor: #1f2440;
  --yellowTwo: #ffb038;
  --yellowThree: #f9b154;
  --brownTwo: #542e02;
  --brownThree: #663f05;
  --descriptionColor: #bbbdc8;
  --descriptionBg: #242a4a;
  --grayInactive: #8f93a4;
  --grayActive: #e7e8ec;
  --gray15: #262626;
  --gray20: #333333;
  --gray77: #c4c4c4;
  --gray88: #e0e0e0;
  --grayTwo: #dfe3e9;
  --grayThree: #ced4da;
  --grayFour: #c4cbd7;
  --grayFive: #e9edf0;
  --voucherCodeColor: #49454f;
  --redTwo: #fe6650;
  --orangeTwo: #df8700;
  --orangeThree: #df6b00;
}

body {
  background: var(--bodyBg);
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.c8F93A4 {
  color: var(--grayInactive);
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.custom-padding {
  padding: 10px 90px;
  @media (max-width: 768px) {
    padding: 10px 20px;
  }
}

.offer-card-container {
  width: 24% !important;
  margin: 6px 6px;
  @media (max-width: 1440px) {
    margin: 4px 4px;
  }
  @media (max-width: 768px) {
    width: 100% !important;
  }
}

.offer-card {
  width: 100% !important;
}

.offer-card-border {
  background: linear-gradient(
    153deg,
    var(--blueFour) 30.42%,
    rgba(71, 73, 86, 0) 74.72%
  );
  border-radius: 8px;
  padding: 1px;
  width: auto;
}

.featured-offer-card-border {
  background: linear-gradient(
    159deg,
    var(--yellowTwo) var(--brownThree),
    var(--brownThree)
  );
  box-shadow: 0 7px 6px rgb(33 34 44 / 25%);
  border-radius: 8px;
}

.dropdown-menu {
  background-color: var(--blueNine);
  border-radius: 0;
  padding: 0;
  overflow: auto;
  max-height: 180px;
  padding: 8px 16px;
  box-shadow: 0 0 1px rgba(21, 45, 75, 0.2), 0 8px 12px rgba(21, 45, 75, 0.1),
    0 0 1px rgba(21, 45, 75, 0.2), 0 -8px 12px rgba(21, 45, 75, 0.1);
  .dropdown-item {
    align-items: center;
    display: flex;
    color: var(--white);
    height: 40px;
    cursor: pointer;
    padding: 8px 32px;
    width: 210px;
    border-bottom: 1px solid transparent;
  }
  .dropdown-item:hover {
    background-color: var(--blueFive);
    border-bottom: 1px solid var(--blueThree);
  }
}

.custom-slider {
  .item {
    width: 100%;
  }
}

.darkBg {
  background-color: var(--primaryDarkBg);
}

.secondary-dark {
  background-color: var(--secondaryDarkBg);
}

.whiteBg {
  background-color: var(--whiteBg);
}

.blue-text {
  color: var(--blueTextColor);
}

.green-text {
  color: var(--greenText);
}

.white-text {
  color: var(--textWhiteColor);
}

.custom-button {
  border-radius: 4px;
  &.primary {
    background-color: var(--blueButtonColor);
    color: var(--textWhiteColor);
    &.outline {
      background-color: transparent;
      border: 1px solid var(--blueTextColor);
    }
  }
  &.primary:hover {
    background-color: var(--blueButtonHoverColor);
  }

  &.secondary {
    background-color: var(--buttonSecondaryBgActive);
    color: var(--textWhiteColor);
    &.outline {
      border: 1px solid var(--blueSix);
    }
  }
  &.secondary:hover {
    background-color: var(--blueButtonHoverColor);
  }
  a {
    color: inherit;
  }

  &.disabled {
    background-color: var(--disabledBtnBg);
    color: var(--grayInactive) !important;
  }
}

.border-primary {
  border: 1px solid var(--blueTextColor);
}

.hideTab .tab-container .nav.nav-tabs {
  display: none;
}

.details-page.tab-container {
  .nav-tabs {
    display: flex;
    flex-flow: row;
    border: unset;
  }
  .nav-item {
    width: 100%;
  }
  .nav-link {
    background-color: inherit !important;
    border: none;
    &.active .custom-tab {
      border: 1px solid var(--blueSeven);
      color: var(--blueTextColor);
      border-radius: 2px;
    }
  }
  .custom-tab {
    background-color: rgba(21, 102, 241, 0.09);
    color: var(--textSecondary);
    height: 32px;
    border: 1px solid var(--blueSix);
    border-radius: 2px;
  }
}

.redemption.tab-container {
  width: 865px;
  @media (max-width: 768px) {
    width: 100%;
  }
  .nav-pills {
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow: scroll;
    display: flex;
    flex-flow: row;
    border: unset;
    margin-bottom: 15px;
    @media (max-width: 768px) {
      padding: 10px 20px;
    }
    .nav-item {
      margin-right: 8px;
    }
  }
  .nav-pills::-webkit-scrollbar {
    display: none;
  }
  .nav-link {
    background-color: var(--blueNineteen);
    border-radius: 55px;
    color: var(--gray77);
    padding: 10px 25px;
    &.active {
      background: linear-gradient(
        96.33deg,
        var(--enabledBtnBg) 37.03%,
        var(--blueButtonColor) 77.17%
      );
      color: var(--white);
    }
    span {
      white-space: nowrap;
    }
  }
}

//Modal styling
.modal-backdrop.show {
  background-color: #111218e6 !important;
}

.custom-modal.token-expired {
  max-width: 380px !important;
  .mat-dialog-container {
    background-color: var(--blueSixteen);
    padding: 0;
  }
}

.footer-modals {
  width: 580px !important;
  max-width: 580px !important;
  max-height: 80vh;
  color: var(--white);
  @media (max-width: 768px) {
    max-width: 100vw !important;
    bottom: 70px;
    max-height: 100vh !important;
  }
  @media (max-width: 386px) {
    max-width: 100vw !important;
  }
  .mat-dialog-container {
    overflow: auto !important;
    background-color: var(--couponModalBgColor) !important;
    color: var(--white);
    padding: 0;
  }
}

.custom-modal {
  .mat-dialog-container {
    background-color: rgba(17, 18, 24, 0.9);
  }

  .modal-content {
    background-color: transparent !important;
  }

  .modal-bg {
    background-color: rgba(17, 18, 24, 0.9);
    border-radius: 12px;
    padding: 32px;
  }

  &.coupon-modal {
    max-width: 345px;
  }

  &.coupon-modal::before {
    content: "";
    height: 32px;
    width: 32px;
    border-radius: 50px;
    background-color: rgba(17, 18, 24, 0.9) !important;
    background-blend-mode: difference;
    position: absolute;
    left: -16px;
    z-index: 1;
  }
  &.coupon-modal::after {
    content: "";
    height: 32px;
    width: 32px;
    border-radius: 50px;
    background-color: rgba(17, 18, 24, 0.9) !important;
    background-blend-mode: difference;
    position: absolute;
    right: -16px;
  }

  .custom-dashed-border {
    border: 1px dashed rgba(196, 203, 215, 0.2);
    &.top {
      border-bottom: 0;
      border-right: 0;
      border-left: 0;
    }
  }
}

.text-decoration-underline {
  text-decoration: underline;
}

.truncate-2-lines {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.cdk-overlay-backdrop {
  background: rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(8px);
}

.contact-modal {
  width: 800px !important;
  max-width: 800px !important;
}

.back-confirmation-modal {
  width: 493px !important;
  max-width: 493px !important;
  min-height: 328px;
}

.select-offer-modal {
  width: 580px !important;
  max-width: 580px !important;
}

.benefits-menu-modal {
  width: 100vw !important;
  animation-duration: 500ms;
}

.select-offer-modal,
.contact-modal,
.back-confirmation-modal,
.benefits-menu-modal {
  max-height: 80vh;
  @media (max-width: 768px) {
    position: absolute !important;
    max-width: 100vw !important;
    bottom: 70px;
  }
  @media (max-width: 386px) {
    max-width: 100vw !important;
  }
  .mat-dialog-container {
    overflow: auto !important;
    background-color: var(--couponModalBgColor);
    color: var(--white);
    padding: 0;
  }
}

.back-confirmation-modal {
  .mat-dialog-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.close-icon {
  width: 13px;
  height: 13px;
  margin-left: auto;
}

.custom-border {
  border: 1px solid var(--blueThree);
  &.top {
    border-top: 1px solid var(--blueThree) !important;
    border-bottom: 0;
    border-right: 0;
    border-left: 0;
  }
  &.right {
    border-right: 1px solid var(--blueThree) !important;
    border-top: 0;
    border-bottom: 0;
    border-left: 0;
  }
  &.bottom {
    border-bottom: 1px solid var(--blueThree) !important;
    border-top: 0;
    border-right: 0;
    border-left: 0;
  }
}

.custom-backdrop-styling {
  height: calc(100vh - 100px);
  bottom: 0;
  top: unset;
}

.authenticationModal {
  width: 420px !important;
  max-width: 420px !important;
  @media (max-width: 768px) {
    max-width: 90vw !important;
  }
  @media (max-width: 386px) {
    max-width: unset !important;
  }
  .mat-dialog-container {
    overflow: visible !important;
    background: var(--couponModalBgColor);
    padding: 41px;
  }
}

.border-rounded-2 {
  border-radius: 2px;
}

.warning {
  &.warning-border {
    border: 1px solid var(--redTwo);
  }
  &.warning-text {
    color: var(--redTwo);
  }
}

.white-variant-light {
  color: var(--grayActive);
}

.focus-active-border:focus-within,
.focus-active-border:focus-visible,
.focus-active-border:hover {
  border: 1px solid var(--blueSeven) !important;
  border-radius: 2px;
}

.form-control:focus {
  color: var(--white);
}

@media (max-width: 768px) {
  .justify-content-center-xs {
    justify-content: center;
  }
}

.w-10px {
  width: 10px;
}

.right.carousel-control,
.left.carousel-control {
  display: none;
}

.carousel-indicators button {
  width: 10px !important;
  height: 8px !important;
  background: rgba(217, 217, 217, 0.33);
  border-radius: 8px;
  border: 0 !important;
  align-self: center;
  &.active {
    background: var(--enabledBtnBg);
    border-radius: 4px;
    width: 24px !important;
    height: 8px !important;
  }
}

img {
  image-rendering: auto;
  image-rendering: crisp-edges;
  image-rendering: pixelated;
  image-rendering: -webkit-optimize-contrast;
}

app-milestone-rewards {
  .mat-tab-header {
    border-bottom: 0;
    .mat-tab-label-container {
      flex-grow: unset;
      margin: auto;
    }
    .milestone-label {
      height: 36px;
      color: var(--grayActive);
      padding: 6px 16px;
    }
    .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
    .mat-tab-label-active {
      background-color: var(--blueTextColor) !important;
      box-shadow: 0 8px 20px rgba(18, 17, 39, 0.1);
      border-radius: 8px;
      opacity: 1;
    }
  }
  .milestone-tab {
    .mat-tab-body-content::-webkit-scrollbar {
      display: none;
    }

    .mat-tab-body-content {
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  }
  .mat-ink-bar {
    display: none;
  }
  .mat-tab-header-pagination-chevron {
    border-color: rgb(255 255 255 / 80%);
  }
  .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
    border-color: rgb(255 255 255 / 38%);
  }
  .blue-text:hover {
    color: var(--blueTextColor) !important;
  }
}

.loader.progress:before {
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0),
    var(--blueEighteen),
    rgba(255, 255, 255, 0)
  ) !important;
  animation: progress 1.5s ease-in-out infinite !important;
}

.grey2-container {
  background: linear-gradient(
    142.04deg,
    var(--gray15) -2.92%,
    var(--blackTwo) 93.19%
  );
  border-radius: 8px;
}

.grey2-container.rounded-bottom-0 {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.grey2-container.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.mar-left-80 {
  margin-left: 80px;
}

.border-transparent {
  border: 1px solid transparent !important;
}

.theme-black.bs-datepicker {
  background-color: var(--blackThree);
  box-shadow: 0 2px 2px rgb(0 0 0 / 10%);
  border-radius: 0 0 4px 4px;
  .bs-datepicker-container {
    padding: 0;
    .bs-datepicker-body {
      border: 0;
      table td span {
        color: var(--whiteFour);
        &.is-other-month {
          color: #f8f9f961;
        }
        &:hover {
          background-color: var(--grayFive);
          color: var(--blackThree);
        }
        &.disabled {
          background-color: var(--blackThree);
          color: #f8f9f931;
          cursor: not-allowed;
        }
      }
    }
  }
}

.bag_icon {
  height: 24px;
}

.merchant-logo {
  object-fit: contain !important;
  img {
    object-fit: contain;
  }
}
